.player-page {
    .player-data {
        text-align: center;
        margin-bottom: 2rem;

        h3 {
            font-size: 2rem;
        }

        div {
            margin-left: 1rem;

            small {
                margin-top: 1rem;
                display: block;
            }
        }

        span {
            cursor: pointer;
        }
    }

    .player {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border-radius: 2.5rem;
            background-color: #252526;
            margin: 0 .5rem;

            &.b-xl {
                svg {
                    font-size: 3rem;
                }

                width: 10rem;
                height: 10rem;
            }

            &.b-l {
                svg {
                    font-size: 2.25rem;
                }

                width: 7.5rem;
                height: 7.5rem;
            }

            &.b-m {
                svg {
                    margin-left: -.2rem;
                    font-size: 1.5rem;
                }

                width: 5rem;
                height: 5rem;
            }
        }
    }
}