@import "./assets/scss/abstracts/abstracts.scss";
@import "./assets/scss/layouts/layouts.scss";
@import './Pages/Artist/Artist.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1E1E1E;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outlet {
  position: absolute;
  top: 3.5rem;
  left: 17.5rem;
  padding: 2rem;
}

.play {
   cursor: pointer;
}