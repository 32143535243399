.tabs-navbar {
    position: fixed;
    top: 2rem;
    left: 15.5rem;
    width: 100%;
    height: 2rem;
    background-color: #252526;
    z-index: 99999;
}

.tabs-navbar-list {
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    margin: auto 0;

    li {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        padding: .5rem 1rem; 
        font-size: .9rem;
        display: flex;
        align-items: center;

        a {
            color: white;
            display: flex;
            align-items: center;
            text-decoration: none;
        }
        
        a img, a svg {
            display: block;
            height: 1rem;
        }

        a span {
            display: block;
            margin: 0 .5rem;
        }
   
        &.active {
            background-color: #333333;
        }
    }
}