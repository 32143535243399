.grid-all-8-6-4-2 {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2rem;

    @include respond(small-desktop) {
        grid-template-columns: repeat(6, 1fr);
    }

    @include respond(tab-land) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include respond(phone) {
        grid-template-columns: repeat(2, 1fr);
    }
}


.grid-all-4-3-2-1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;

    @include respond(small-desktop) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid-all-3-2-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(tab-port) {
        grid-template-columns: repeat(1, 1fr);
    }
}