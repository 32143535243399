@mixin respond($breakpoint) {

    @if $breakpoint == phone {
        @media (max-width: 40rem) { @content };
    }

    @if $breakpoint == small-tab-port {
        @media (max-width: 55rem) { @content };
    }

    @if $breakpoint == tab-port {
        @media (max-width: 65rem) { @content };
    }

    @if $breakpoint == small-tab-land {
        @media (max-width: 72.5rem) { @content };
    }

    @if $breakpoint == tab-land {
        @media (max-width: 80rem) { @content };
    }

    @if $breakpoint == small-desktop {
        @media (max-width: 100rem) { @content };
    }

    @if $breakpoint == desktop {
        @media (max-width: 120rem) { @content };
    }

    @if $breakpoint == big-desktop {
        @media (max-width: 140rem) { @content };
    }
}