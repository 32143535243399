.searchForm {
    display: flex;
    align-items: center;
    justify-content: center;
  
    input {
        width: 40%;
        background-color: whitesmoke;
        color: #000;
    }
}

.searchPage {

    section {
        margin-bottom: 2rem;
    }

    .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5rem;

        div {
            display: flex;
            align-items: center; 
            justify-content: space-between;

            &.artist, &.album, &.track {
                border-radius: .5rem;
                cursor: pointer;
                background-color: #111;
                margin-bottom: 1rem;
    
                img {
                    border-top-left-radius: .5rem;
                    border-bottom-left-radius: .5rem;
                    height: 4rem;
                    width: 4rem;
                }
            
                span {
                    padding: 1rem;
                }
            }
        }
    }
}