.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login form {
    margin: auto;
}

.login button {
    display: block;
    margin: auto;
}