.sidebar {
    position: fixed;
    top: 2rem;
    left: 0;
    height: 100vh;
    width: 3rem;
    background-color: #333333;
    align-items: center;
}

.sidebar-icons span {
    cursor: pointer;
    color: #adadad;
    text-decoration: none;
    padding: .5rem 0;
    font-size: 1.5rem;
    text-align: center;
    display: block;

    &:hover {
        color: #fbfbfb;
    }
}