.explorer {
    position: fixed;
    top: 2rem;
    left: 3rem;
    height: 100vh;
    width: 15rem;
    background-color: #252526;
    display: flex;
    flex-direction: column;

    svg {
        font-size: 1.2rem;
        margin-right: .5rem;
        cursor: pointer;
    }
}

.explorer-header {
    width: 15rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        display: block;
        text-transform: uppercase;
        font-size: .75rem;
    }
}

.explorer-songs {
    div.explorer-song {
        display: flex;
        justify-content: space-between;
        margin: .5rem .75rem;

        div {
            display: flex;
            justify-content: space-between;
            align-items: center; 
        }

        span {
            display: block;
            font-size: .9rem;

            .play {
                cursor: pointer;
                margin-left: auto;
            } 
        } 

        img {
            margin-right: .75rem;
            height: 2.25rem;
        } 
    } 
}

.explorer-playing-song {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .5rem .75rem;

    svg {
        display: block;
    } 

    small {
        cursor: pointer;
    }
}

.explorer-playing-song-left {
    display: flex;

    img {
        margin-right: 1rem;
        height: 4rem;
        cursor: pointer;
    } 

    span {
        display: block;
        font-size: .9rem;
    } 

    small {
        display: block;
        margin-top: .15rem;
        font-size: .7rem;
    } 
} 

span.section-title {
    background-color: #333333;
    padding: .2rem;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .7rem;
    text-align: center;
}

span.no-explorer-data {
    display: block;
    text-align: center;
    margin: 1rem;
    font-size: .8rem;
}

.saved {
    color: #1DD760;
}