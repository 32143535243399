.historyPage {

    .tracks {
        td.title {
            display: flex;
            align-items: center;

            span {
                display: block;
                margin-left: 1rem;
            }
        }

        img {
            border-radius: .5rem;
            height: 4rem;
            width: 4rem;
        }
    }
}