.artist {
    display: flex;
    justify-content: space-between;

    img {
        height: 12.5rem;
        border-radius: .5rem;
    }

    &-header {
        display: flex;
        margin-bottom: 1rem;

        &-data {
            margin-left: 2rem;
        }
    }

    &-albums {
        position: relative;
        @extend .grid-all-8-6-4-2;
    }

    &-album {
        border-radius: .5rem;
        background-color: #252526;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        img {
            border-radius: .5rem;
        }
        
        span {
            margin-top: 1rem;
        }

        div {
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }
    }
}

.mb-2 {
    margin-bottom: 2rem;
}