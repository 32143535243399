.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background-color: #252526;
    z-index: 99999;
}

.navbar-list {
    list-style: none;
    display: flex;
    align-items: center;
    margin: auto 0;
}

.navbar-center {
    position: fixed;
    top: 0;
    right: 10px;
    height: 2rem;
    background-color: #252526;
}

.navbar-list-center {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
    height: 100%;
}

.navbar-list li, .navbar-list-center li {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    padding: .25rem .5rem; 
    border-radius: .25rem;
    font-size: .75rem;
}

.navbar-list li:hover {
    background-color: #555;
}